import { copyToClipboard } from '~/shared/utils/copy-to-clipboard';

const bindCopyButtons = () => {
  const copyButtons = document.querySelectorAll('[data-copy-to-clipboard]');

  copyButtons.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      copyToClipboard(e.currentTarget);
    });
  });
};

const bindEventListeners = () => {
  bindCopyButtons();
};

document.addEventListener('DOMContentLoaded', async () => {
  // Service create/edit form and select media dialogs
  const { ServiceFormDialog } = await import('~/services/service-form-dialog');
  ServiceFormDialog.init();

  const { ServiceSelectMediaDialog } = await import('~/services/service-select-media-dialog');
  ServiceSelectMediaDialog.init();

  // Missing payout provider warning dialog
  const { ConnectPayoutDialog } = await import('~/marketplace/connect-payout-dialog');
  ConnectPayoutDialog.init();

  bindEventListeners();
});
