export async function copyToClipboard(element) {
  const text = element?.getAttribute('data-copy-to-clipboard') || element?.textContent;
  if (!text) return;

  try {
    await navigator.clipboard.writeText(text);
    element.setAttribute('data-copy-success', '');

    setTimeout(() => {
      element.removeAttribute('data-copy-success');
    }, 2000);
  } catch (error) {
    console.warn('Failed to copy text', error);
  }
}
